import React from 'react';
import {Button, Spinner} from "react-bootstrap";

const SubmitButton = ({isSubmitting, lul}) => {
    return (
        <div className="d-grid">
            <Button
                variant="primary"
                type="submit"
                size="lg"
            >
                {isSubmitting ? (
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    <>{lul}</>
                )}
            </Button>
        </div>
    );
};

export default SubmitButton;