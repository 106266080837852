import {format, parseISO} from 'date-fns'

export default function CustomDate({dateString, customFormat}) {
    const formato = customFormat ? customFormat : 'dd.MM.yyyy';
    const date = parseISO(dateString)
    return <time dateTime={dateString}>{format(date, formato)}</time>
}

export function formatDate(dateString, customFormat) {
    const formato = customFormat ? customFormat : 'dd.MM.yyyy';
    return format(dateString, formato);
}