import React from 'react';
import {Form, Modal} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {TextField} from "../../inputs/TextField";
import NiceModal, {bootstrapDialog, useModal} from "@ebay/nice-modal-react";
import {errorNotification, successNotification} from "../../../utils/Helpers";
import ActionRowButton from "../../../shared/ActionRowButtons";
import {USERNAME_MAX_LENGTH, USERNAME_MIN_LENGTH} from "../../../constants";
import {CheckboxField} from "../../inputs/CheckboxField";
import axios from "axios";

const UserEditModal = NiceModal.create(({user}) => {
    const modal = useModal();
    return (
        <Modal
            centered
            {...bootstrapDialog(modal)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Benutzer bearbeiten</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                        username: user.username,
                        email: user.email,
                        active: user.active,
                        enabled: user.enabled,
                        admin: user.roles.indexOf("Admin") > -1,
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string()
                            .min(USERNAME_MIN_LENGTH, "Zu kurz!")
                            .max(USERNAME_MAX_LENGTH, "Zu lang!")
                            .required("Benutzername muss angegeben werden"),
                        email: Yup.string().email("E-Mail ist nicht gültig").required("Bitte gib eine Email ein."),
                    })}
                    onSubmit={(values, actions) => {
                        const data = {
                            id: user.id,
                            username: values.username,
                            email: values.email,
                            active: values.active,
                            enabled: values.enabled,
                            admin: values.admin,
                        };
                        axios
                            .put("/admin/users", data)
                            .then(() => {
                                successNotification("Benutzer erfoglreich bearbeitet");
                                modal.resolve();
                                modal.hide();
                            })
                            .catch(() => {
                                actions.setSubmitting(false);
                                errorNotification();
                            });
                    }}
                >
                    {({
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <TextField label={"Benutzername"} name="username" type="text" required autoFocus/>
                            <TextField label={"E-Mail"} name="email" type="email" required/>
                            <CheckboxField
                                label={"Aktiv"}
                                name={"active"}
                                id={'active'}
                                defaultChecked={user.active}
                            />
                            <CheckboxField
                                label={"Admin"}
                                name={"admin"}
                                id={'admin'}
                                defaultChecked={user.roles.indexOf("Admin") > -1}
                            />
                            <Form.Group className={"d-flex justify-content-end"}>
                                <ActionRowButton
                                    close={modal.hide}
                                    handleSubmit={handleSubmit}
                                    isSubmitting={isSubmitting}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
});

export default UserEditModal;
