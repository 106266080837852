import React from 'react';
import {Badge} from "react-bootstrap";

const StatusBadge = ({status, yesText, noText}) => {
    if (status) {
        return <Badge>{yesText}</Badge>
    } else {
        return (
            <Badge bg={"danger"}>{noText}</Badge>
        );
    }
};

export default StatusBadge;