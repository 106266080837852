import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {Navigate, useLocation} from 'react-router-dom';
// import {intersection} from 'lodash';

const PrivateRoute = ({component, roles}) => {
    let location = useLocation();
    const user = useSelector(state => state.user);
    // const userHasRequiredRole = !!(user.currentUser && intersection(user.currentUser.roles, roles).length > 0)

    if (!user.isAuthenticated) {
        return <Navigate to="/" state={{from: location}}/>;
    }

    // if (user.isAuthenticated && !userHasRequiredRole) {
    //     return <AccessDenied/>
    // }

    return component;
};

PrivateRoute.propTypes = {
    roles: PropTypes.array,
};

PrivateRoute.defaultProps = {
    roles: ['Benutzer']
};

export default PrivateRoute;