import React, {useEffect} from 'react';
import * as Yup from "yup";
import {ACCESS_TOKEN, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH} from "../../constants";
import {errorNotification} from "../../utils/Helpers";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";
import "./Login.scss";
import {useSelector} from "react-redux";
import LayoutStatic from "../../components/layout/LayoutStatic";
import {TextField} from "../../components/inputs/TextField";
import SubmitButton from "../../components/inputs/SubmitButton";
import axios from "axios";

const Login = (props) => {
    const navigate = useNavigate();
    const user = useSelector(state => state.user);

    useEffect(() => {
        if (user && user.isAuthenticated) {
            navigate("/");
        }
        // eslint-disable-next-line
    }, []);

    return (
        <LayoutStatic>
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                    username: "",
                    password: "",
                }}
                validationSchema={Yup.object().shape({
                    username: Yup.string()
                        .min(3, "Zu kurz!")
                        // .max(USERNAME_MAX_LENGTH, t('INPUT.ERROR.TOLONG'))
                        .required("Benutzername muss angegeben werden\n"),
                    password: Yup.string()
                        .min(PASSWORD_MIN_LENGTH, "Zu kurz!")
                        .max(PASSWORD_MAX_LENGTH, "Zu lang!")
                        .required("Bitte gib ein Passwort ein"),
                })}
                onSubmit={(values, actions) => {
                    const data = {
                        usernameOrEmail: values.username,
                        password: values.password,
                    };
                    axios
                        .post("/auth/admin/signin", data)
                        .then((res) => {
                            localStorage.setItem(ACCESS_TOKEN, res.data.accessToken);
                            props.onLogin();
                        })
                        .catch((error) => {
                            actions.setSubmitting(false);
                            if (error != null) {
                                if (error.response !== undefined) {
                                    if (error.response.status === 401) {
                                        errorNotification("Benutzername oder Passwort sind nicht korrekt. Bitte versuche es erneut!");
                                    } else {
                                        errorNotification();
                                    }
                                } else {
                                    errorNotification();
                                }
                            }
                        });
                }}
            >
                {({
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <section className="h-100 login-container">
                        <div className="container h-100">
                            <div className="row justify-content-sm-center h-100">
                                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
                                    <div className="text-center my-5"/>
                                    <div className="card shadow-lg mb-5">
                                        <div className="card-body p-md-5">
                                            <h1 className="fs-4 card-title fw-bold mb-4 text-dark">Adminlogin</h1>
                                            <form noValidate onSubmit={handleSubmit}>
                                                <TextField label="Benutzername" name="username" type="text"
                                                           autoFocus/>
                                                <TextField
                                                    label={<>
                                                        <label htmlFor="password">Passwort</label>
                                                    </>}
                                                    name="password"
                                                    type="password"
                                                />
                                                <SubmitButton isSubmitting={isSubmitting} lul="Anmelden"/>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </Formik>
        </LayoutStatic>
    );
};

export default Login;