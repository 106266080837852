import {toast} from 'react-toastify';
import axios from "axios";

export const getTextContentsFromHtmlString = html => {
    const el = document.createElement('div');
    el.innerHTML = html;
    return el.textContent;
};

export function scrollUp() {
    window.scrollTo({
        top: 0,
    });
}

export const firstLetter = item => item.substring(0, 1).toUpperCase();

export function truncate(name, cut = 75) {
    return name.length > cut ? name.substring(0, cut) + "..." : name;
}

export const copyToClipboard = (value) => {
    const $textarea = document.createElement("textarea");
    $textarea.value = value;
    document.body.appendChild($textarea);
    $textarea.select();
    document.execCommand("copy");
    document.body.removeChild($textarea);
};

export function errorNotification(msg) {
    toast.error(msg === undefined ? "Sorry! Etwas ist schief gelaufen. Bitte versuche es erneut!" : msg,
        {
            position: "top-right",
            theme: "dark",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
}

export function successNotification(msg, placemend = "top-right") {
    toast.success(msg === undefined ? "Erfolgreich" : msg,
        {
            position: placemend,
            theme: "dark",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
}


export function getSetting(setting) {
    axios.get("setting/" + setting)
        .then((res) => {
            return res.data;
        })
        .catch(() => {
            return null;
        });
}

export function pageTitle(page) {
    if (page.includes("/") && page.length === 1) {
        return "Dashboard";
    } else if (page.includes("/inventar")) {
        return "Lagerort Details";
    } else if (page.includes("/einstellungen")) {
        return "Einstellungen";
    }
    // switch (page) {
    //     case "/":
    //         return "Dashboard";
    //     case "/inventar/":
    //         return "mittel";
    //     default:
    //         return "";
    // }
}
