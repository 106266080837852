import {configureStore} from '@reduxjs/toolkit';
import userReducer from "./UserSlice";
import groupReducer from "./GroupSlice";
import {env} from "../constants";

export default configureStore({
    reducer: {
        user: userReducer,
        group: groupReducer
    },
    devTools: env !== "prod",
})