import React, {useEffect, useState} from 'react';
import {Form, Modal} from "react-bootstrap";
import {Formik} from "formik";
import {TextField} from "../../inputs/TextField";
import NiceModal, {bootstrapDialog, useModal} from "@ebay/nice-modal-react";
import {errorNotification, successNotification} from "../../../utils/Helpers";
import ActionRowButton from "../../../shared/ActionRowButtons";
import LoadingIndicator from "../../loading/LoadingIndicator";
import axios from "axios";

const SettingsAddModal = NiceModal.create(() => {
    const modal = useModal();
    const [loading, setLoading] = useState(true);
    const [names, setNames] = useState([]);

    useEffect(() => {
        loadNames();
    }, []);

    function loadNames() {
        setLoading(true);
        axios
            .get("/admin/settings/names")
            .then((res) => {
                setNames(res.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    if (loading) {
        return <LoadingIndicator/>;
    }

    return (
        <Modal
            centered
            {...bootstrapDialog(modal)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Einstellung hinzufügen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        type: "BOOL",
                        name: names[0],
                        defaultValue: "",
                        active: true
                    }}
                    onSubmit={(values, actions) => {
                        const data = {
                            type: values.type,
                            name: values.name,
                            defaultValue: values.defaultValue,
                            active: values.active
                        };
                        axios
                            .post("/admin/settings", data)
                            .then(() => {
                                successNotification("Einstellung erfoglreich angelegt");
                                modal.resolve();
                                modal.hide();
                            })
                            .catch((error) => {
                                actions.setSubmitting(false);
                                errorNotification(error.response.data.message);
                            });
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          isSubmitting,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="name" className={"mb-3"}>
                                <Form.Label>Name</Form.Label>
                                <Form.Select onChange={handleChange}>
                                    {names.map((key) => (
                                        <option key={key} value={key}>{key}</option>
                                    ))}
                                </Form.Select>
                                <Form.Text>
                                    Doppelte einträge können nicht angelegt werden
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="type" className={"mb-3"}>
                                <Form.Label>Typ</Form.Label>
                                <Form.Select onChange={handleChange}>
                                    <option value={"BOOL"}>BOOL</option>
                                    <option value={"VALUE"}>VALUE</option>
                                </Form.Select>
                            </Form.Group>
                            <TextField label={"Standardwert"} name="defaultValue" type="text"
                                       formText={"Wenn BOOL, dann true oder false klein reinschreiben"}/>
                            <Form.Group controlId="active" className={"mb-3"}>
                                <Form.Check
                                    name="active"
                                    defaultChecked={true}
                                    label="Aktiv"
                                    onChange={handleChange}
                                    id="validationFormikactive"
                                />
                            </Form.Group>
                            <Form.Group className={"d-flex justify-content-end"}>
                                <ActionRowButton
                                    close={modal.hide}
                                    handleSubmit={handleSubmit}
                                    isSubmitting={isSubmitting}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
});

export default SettingsAddModal;