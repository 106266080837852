import React, {useEffect, useState} from 'react';
import {Container, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import "./Header.scss";

const Header = () => {
    const [atTopOfPage, setAtTopOfPage] = useState(true);

    useEffect(() => {
        const onScroll = () => {
            if (window.pageYOffset > 0) {
                if (atTopOfPage) {
                    setAtTopOfPage(false);
                }
            } else {
                setAtTopOfPage(true);
            }
        };
        // clean up code

        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, {passive: true});
        return () => window.removeEventListener('scroll', onScroll);
        // eslint-disable-next-line
    }, []);

    return (
        <Navbar bg="light" variant="light" expand={"lg"} className={`${atTopOfPage ? '' : 'scrolled'} py-0`}
                sticky={"top"}>
            <Container>
                <Navbar.Brand as={Link} to={"/"}>
                    <span className='brand-custom'> Inv<span>onizer</span></span>
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
};

export default Header;
