import {ToastContainer} from "react-toastify";
import {Route, Routes, useNavigate} from "react-router-dom";
import "./styles/App.scss";
import 'react-toastify/dist/ReactToastify.css';
import {useEffect, useState} from "react";
import {ACCESS_TOKEN} from "./constants";
import LoadingIndicator from "./components/loading/LoadingIndicator";
import {update} from "./redux/UserSlice";
import {useDispatch, useSelector} from "react-redux";
import AppHeader from "./components/header/AppHeader";
import Login from "./pages/login/Login";
import PrivateRoute from "./common/PrivateRoute";
import NotFound from "./common/NotFound";
import Dashboard from "./components/dashboard/Dashboard";
import UserList from "./components/users/UserList";
import ChangelogList from "./components/changelog/ChangelogList";
import ChangelogEntry from "./components/changelog/ChangelogEntry";
import SettingsList from "./components/settings/SettingsList";
import "./utils/Api";
import axios from "axios";

function App() {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        loadCurrentUser();
        // eslint-disable-next-line
    }, []);

    function handleLogin() {
        loadCurrentUser();
        navigate("/");
    }

    function loadCurrentUser() {
        setLoading(true);
        if (!localStorage.getItem(ACCESS_TOKEN)) {
            setLoading(false);
            return;
        }

        axios.get("/user/me")
            .then((response) => {
                dispatch(update({user: response.data, auth: true}));
                setLoading(false);
            })
            .catch(() => {
                localStorage.removeItem(ACCESS_TOKEN);
                dispatch(update({user: null, auth: false}));
                setLoading(false);
            });
    }

    if (loading) {
        return <LoadingIndicator/>;
    }

    return (
        <div className="App">
            <ToastContainer limit={3} newestOnTop pauseOnFocusLoss={false}/>
            <Routes>
                {user.isAuthenticated && (
                    <Route path={"/"} element={<PrivateRoute component={<AppHeader/>}/>}>
                        <Route index element={<PrivateRoute component={<Dashboard/>}/>}/>
                        <Route path={"users/list"} element={<PrivateRoute component={<UserList/>}/>}/>
                        <Route path={"changelogs"} element={<PrivateRoute component={<ChangelogList/>}/>}/>
                        <Route path={"changelog-entry/:id"} element={<PrivateRoute component={<ChangelogEntry/>}/>}/>
                        <Route path={"settings"} element={<PrivateRoute component={<SettingsList/>}/>}/>
                        <Route path='*' element={<NotFound/>}/>
                    </Route>
                )}
                {!user.isAuthenticated && (
                    <>
                        <Route path="/" element={<Login onLogin={handleLogin}/>}/>
                        <Route path='*' element={<NotFound/>}/>
                    </>
                )}

            </Routes>
        </div>
    );
}

export default App;
