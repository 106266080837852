import React from 'react';
import {useField} from 'formik';
import {Form} from "react-bootstrap";

export const TextField = ({label, referenz, formText, required, ...props}) => {
    const [field, meta] = useField(props);
    return (
        <Form.Group className="mb-3">
            <div className={`mb-2 w-100 text-black ${required && 'required'}`}>
                {label}
            </div>
            <Form.Control
                type="text"
                isInvalid={!!meta.error && meta.touched}
                {...field} {...props}
                ref={referenz}
            />
            <Form.Control.Feedback type="invalid">
                {meta.error}
            </Form.Control.Feedback>
            {formText && formText.length > 0 && (
                <Form.Text className="text-muted">
                    {formText}
                </Form.Text>
            )}
        </Form.Group>
    )
}