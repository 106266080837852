import React from 'react';
import {IoIosArrowBack} from "react-icons/io";
import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";

const BackButton = () => {
    const navigate = useNavigate();
    return (
        <div className={"d-flex align-items-center mb-2"}>
            <Button onClick={() => navigate(-1)} variant={"link"} className={"ps-0 text-decoration-none"}>
                <IoIosArrowBack/> Zurück
            </Button>
        </div>
    );
};

export default BackButton;