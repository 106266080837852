import React, {useState} from 'react';
import {Form, Modal} from "react-bootstrap";
import {Formik} from "formik";
import NiceModal, {bootstrapDialog, useModal} from "@ebay/nice-modal-react";
import {successNotification} from "../../../utils/Helpers";
import ActionRowButton from "../../../shared/ActionRowButtons";
import axios from "axios";
import {formatDate} from "../../../utils/CustomDate";

const ChangelogDateAddModal = NiceModal.create(() => {
    const modal = useModal();
    const [today] = useState(formatDate(new Date(), 'yyyy-MM-dd'));
    return (
        <Modal
            centered
            {...bootstrapDialog(modal)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Changelog Datum anlegen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        date: today,
                        active: true,
                    }}
                    onSubmit={(values, actions) => {
                        const data = {
                            date: values.date,
                            active: values.active
                        };
                        axios.post("/admin/changelogs", data)
                            .then(() => {
                                successNotification("Changelog Datum wurde erfolgreich hinzugefügt.");
                                modal.resolve();
                                modal.hide();
                            })
                            .catch((error) => {
                                actions.setSubmitting(false);
                                actions.setErrors({date: error.response.data.message});
                            })
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          isSubmitting,
                          touched,
                          errors,
                      }) => (
                        <>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="date" className={"mb-3"}>
                                    <Form.Label>Datum</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name='date'
                                        error={errors.date}
                                        value={values.date}
                                        isValid={touched.date && !errors.date}
                                        isInvalid={!!errors.date && touched.date}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.date}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="active" className={"mb-3"}>
                                    <Form.Check
                                        name="active"
                                        label="Aktiv"
                                        onChange={handleChange}
                                        isInvalid={!!errors.active}
                                        feedback={errors.active}
                                        defaultChecked={true}
                                        id="validationFormik2"
                                    />
                                </Form.Group>
                                <Form.Group className={"d-flex justify-content-end"}>
                                    <ActionRowButton
                                        close={modal.hide}
                                        handleSubmit={handleSubmit}
                                        isSubmitting={isSubmitting}
                                    />
                                </Form.Group>
                            </Form>
                        </>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
});

export default ChangelogDateAddModal;