import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";
import App from './App';
import {Provider} from "react-redux";
import store from "./redux/Store";
import NiceModal from '@ebay/nice-modal-react';
import axios from "axios";
import {API_BASE_URL} from "./constants";

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <Router>
            <NiceModal.Provider>
                <App/>
            </NiceModal.Provider>
        </Router>
    </Provider>
);