import React, {useEffect, useState} from 'react';
import Layout from "../layout/Layout";
import {Button, Card, Table} from "react-bootstrap";
import {MdAdd} from "react-icons/md";
import StatusBadge from "../../shared/StatusBadge";
import NiceModal from "@ebay/nice-modal-react";
import LoadingIndicator from "../loading/LoadingIndicator";
import {RiPencilLine} from "react-icons/ri";
import SettingsAddModal from "./modals/SettingsAddModal";
import SettingsEditModal from "./modals/SettingsEditModal";
import axios from "axios";

const SettingsList = () => {
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        loadSettings();
        // eslint-disable-next-line
    }, []);

    function loadSettings() {
        setLoading(true);
        axios
            .get("/admin/settings")
            .then((res) => {
                setSettings(res.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    function createClick() {
        NiceModal.show(SettingsAddModal).then(() => {
            loadSettings();
        });
    }

    function editClick(item) {
        NiceModal.show(SettingsEditModal, {item: item}).then(() => {
            loadSettings();
        });
    }

    if (loading) {
        return <LoadingIndicator/>
    }
    return (
        <Layout>
            <h2 className={"mb-3"}>Einstellungsverwaltung</h2>
            <Card className={"shadow border-0 mb-4"}>
                <Card.Body>
                    <div className="d-flex justify-content-end mb-3">
                        <div>
                            <Button onClick={createClick}>
                                <MdAdd/> Hinzufügen
                            </Button>
                        </div>
                    </div>
                    <div>
                        <Table responsive hover className={"align-middle"}>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Typ</th>
                                <th>Standardwert</th>
                                <th>Status</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {settings.map((key) => (
                                <tr key={key.id}>
                                    <td>{key.id}</td>
                                    <td>{key.name}</td>
                                    <td>{key.type}</td>
                                    <td>{key.defaultValue}</td>
                                    <td><StatusBadge status={key.active} yesText={"Aktiv"} noText={"Inaktiv"}/></td>
                                    <td>
                                        <RiPencilLine className="pencil"
                                                      onClick={(() => editClick(key))}/>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default SettingsList;