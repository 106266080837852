import {createSlice} from '@reduxjs/toolkit'

export const groupSlice = createSlice({
    name: "group",
    initialState: {
        groups: [],
        group: {
            id: 0,
            name: "",
            slug: ""
        },
    },
    reducers: {
        updateGroups: (state, action) => {
            state.groups = action.payload;
        },
        addGroup: (state, action) => {
            state.groups.push(action.payload);
        },
        selectGroup: (state, action) => {
            state.group = action.payload;
        },
        updateGroup: (state, action) => {
            let id = parseInt(action.payload);
            state.groups.forEach((item, key) => {
                if (item.id === id) {
                    state.group = state.groups[key];
                }
            })
        },
        updateGroupName: (state, action) => {
            let id = parseInt(action.payload.id);
            state.groups.forEach((item, key) => {
                if (item.id === id) {
                    state.groups[key].name = action.payload.name;
                    state.groups[key].slug = action.payload.slug;
                    state.group = state.groups[key];
                }
            })
        }
    }
});

export const {updateGroup, updateGroups, updateGroupName, addGroup, selectGroup} = groupSlice.actions;
export default groupSlice.reducer;