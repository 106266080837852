import React from 'react';
import {Form, Modal} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import NiceModal, {bootstrapDialog, useModal} from "@ebay/nice-modal-react";
import {errorNotification, successNotification} from "../../../utils/Helpers";
import ActionRowButton from "../../../shared/ActionRowButtons";
import axios from "axios";

const ChangelogEntryAddModal = NiceModal.create(({id}) => {
    const modal = useModal();
    return (
        <Modal
            centered
            {...bootstrapDialog(modal)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Changelog Eintrag erstellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        changelogDateId: id,
                        title: "",
                        text: "",
                        type: "NEW"
                    }}
                    validationSchema={Yup.object().shape({
                        type: Yup.string().required("Bitte gib einen Typ an."),
                        text: Yup.string().required("Text muss angebenen werden.")
                    })}
                    onSubmit={(values, actions) => {
                        const data = {
                            changelogDateId: id,
                            title: values.title,
                            text: values.text,
                            type: values.type
                        };
                        axios
                            .post("/admin/changelog-entry", data)
                            .then(() => {
                                successNotification("Changelog wurde erfolgreich hinzugefügt");
                                modal.resolve();
                                modal.hide();
                            })
                            .catch(() => {
                                actions.setSubmitting(false);
                                errorNotification();
                            })
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          isSubmitting,
                          values,
                          errors,
                          touched
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId={"type"} className={"mb-3"}>
                                <Form.Select aria-label="Default select example" onChange={handleChange}
                                             value={values.type}>
                                    <option value="NEW">Neu</option>
                                    <option value="IMPROVED">Verbesserung</option>
                                    <option value="FIX">Bugfix</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group controlId="title" className={"mb-3"}>
                                <Form.Control
                                    type="text"
                                    placeholder="Titel"
                                    onChange={handleChange}
                                    value={values.title}
                                />
                            </Form.Group>
                            <Form.Group controlId="text" className={"mb-3"}>
                                <Form.Control
                                    as="textarea" rows={3}
                                    onChange={handleChange}
                                    value={values.text}
                                    placeholder="Hier Änderung beschreiben"
                                    isInvalid={!!errors.text && touched.text}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.text}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className={"d-flex justify-content-end"}>
                                <ActionRowButton
                                    close={modal.hide}
                                    handleSubmit={handleSubmit}
                                    isSubmitting={isSubmitting}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
});

export default ChangelogEntryAddModal;