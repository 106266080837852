import React, {useEffect, useState} from 'react';
import Layout from "../layout/Layout";
import WidgetWithTitleAndIcon from "../../shared/widgets/WidgetWithTitleAndIcon";
import {MdFlag, MdFormatAlignJustify, MdPerson} from "react-icons/md";
import {Col, Row} from "react-bootstrap";
import axios from "axios";

const Dashboard = () => {
    const [userCount, setUserCount] = useState(0);
    const [boxCount, setBoxCount] = useState(0);
    const [articleCount, setArticleCount] = useState(0);
    const [newUserCount, setNewUserCount] = useState(0);

    useEffect(() => {
        loadDashboardData();
        // eslint-disable-next-line
    }, []);

    function loadDashboardData() {
        axios
            .get("/admin/dashboard")
            .then((res) => {
                setUserCount(res.data.userCount);
                setBoxCount(res.data.boxCount);
                setArticleCount(res.data.articleCount);
                setNewUserCount(res.data.newUserCount);
            })
            .catch(() => {
            });
    }

    return (
        <Layout>
            <h2 className={"mb-3"}>Dashboard</h2>
            <Row>
                <Col xl={3} lg={6} md={6} sm={12}>
                    <WidgetWithTitleAndIcon title={"Benutzer"} number={userCount}
                                            icon={<MdPerson size={25} className="startpage-icon text-primary"/>}
                                            className={"bg-primary-light"}/>
                </Col>
                <Col xl={3} lg={6} md={6} sm={12}>
                    <WidgetWithTitleAndIcon title={"Gruppen"} number={boxCount}
                                            icon={<MdFormatAlignJustify size={25}
                                                                        className="startpage-icon text-primary"/>}
                                            className={"bg-primary-light"}/>
                </Col>
                <Col xl={3} lg={6} md={6} sm={12}>
                    <WidgetWithTitleAndIcon title={"Artikel"} number={articleCount}
                                            icon={<MdFlag size={25} className="startpage-icon text-primary"/>}
                                            className={"bg-primary-light"}/>
                </Col>
                <Col xl={3} lg={6} md={6} sm={12}>
                    <WidgetWithTitleAndIcon title={"Neue Benutzer (Letzte 7 Tage)"} number={newUserCount}
                                            icon={<MdPerson size={25} className="startpage-icon text-primary"/>}
                                            className={"bg-primary-light"}/>
                </Col>
            </Row>

        </Layout>
    );
};

export default Dashboard;