import React from 'react';
import {Form, Modal} from "react-bootstrap";
import {Formik} from "formik";
import NiceModal, {bootstrapDialog, useModal} from "@ebay/nice-modal-react";
import {errorNotification, successNotification} from "../../../utils/Helpers";
import ActionRowButton from "../../../shared/ActionRowButtons";
import axios from "axios";

const ChangelogDateAddModal = NiceModal.create(({item}) => {
    const modal = useModal();
    return (
        <Modal
            centered
            {...bootstrapDialog(modal)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Changelog Datum bearbeiten</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        date: item.date,
                        active: item.active
                    }}
                    onSubmit={(values, actions) => {
                        const data = {
                            id: item.id,
                            date: values.date,
                            active: values.active,
                        };
                        axios.put("/admin/changelogs", data)
                            .then(() => {
                                successNotification("Changelog Datum wurde erfolgreich bearbeitet.");
                                modal.resolve();
                                modal.hide();
                            })
                            .catch(() => {
                                actions.setSubmitting(false);
                                errorNotification();
                            })
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          isSubmitting,
                          errors,
                      }) => (
                        <>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="date" className={"mb-3"}>
                                    <Form.Label>Datum</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name='date'
                                        value={values.date}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="active" className={"mb-3"}>
                                    <Form.Check
                                        defaultChecked={
                                            item.active
                                        }
                                        name="active"
                                        label="Aktiv"
                                        onChange={handleChange}
                                        isInvalid={!!errors.active}
                                        feedback={errors.active}
                                        id="validationFormik2"
                                    />
                                </Form.Group>
                                <Form.Group className={"d-flex justify-content-end"}>
                                    <ActionRowButton
                                        close={modal.hide}
                                        handleSubmit={handleSubmit}
                                        isSubmitting={isSubmitting}
                                    />
                                </Form.Group>
                            </Form>
                        </>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
});

export default ChangelogDateAddModal;