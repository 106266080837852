import React from 'react';
import {Form, Modal} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {TextField} from "../../inputs/TextField";
import NiceModal, {bootstrapDialog, useModal} from "@ebay/nice-modal-react";
import ActionRowButton from "../../../shared/ActionRowButtons";
import {PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH} from "../../../constants";
import {errorNotification, successNotification} from "../../../utils/Helpers";
import {CheckboxField} from "../../inputs/CheckboxField";
import axios from "axios";

const PasswordResetModal = NiceModal.create(({user}) => {
    const modal = useModal();
    return (
        <Modal
            centered
            {...bootstrapDialog(modal)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Passwort zurücksetzen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                        password: "",
                        generatePassword: false,
                        sendMail: true
                    }}
                    validationSchema={Yup.object().shape({
                        password: Yup.string()
                            .when("generatePassword", {
                                is: (val) => val === false,
                                then: Yup.string()
                                    .min(PASSWORD_MIN_LENGTH, "Zu kurz!")
                                    .max(PASSWORD_MAX_LENGTH, "Zu lang!")
                                    .required("Bitte gib ein Passwort ein"),
                                otherwise: Yup.string().notRequired()
                            }),
                    })}
                    onSubmit={(values, actions) => {
                        const data = {
                            id: user.id,
                            password: values.password,
                            generatePassword: values.generatePassword,
                            sendMail: values.sendMail
                        };
                        axios
                            .post("/admin/users/password-reset", data)
                            .then(() => {
                                successNotification("Passwort erfolgreich zurückgesetzt");
                                modal.resolve();
                                modal.hide();
                            })
                            .catch(() => {
                                actions.setSubmitting(false);
                                errorNotification();
                            });
                    }}
                >
                    {({
                          handleSubmit,
                          isSubmitting,
                          values
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <div className={"mb-3 fs-5"}>
                                Passwort für <span className={"text-primary"}>{user.username}</span> zurücksetzen
                            </div>
                            <CheckboxField
                                label={"Passwort generieren"}
                                name={"generatePassword"}
                                id={'generatePassword'}
                            />
                            <CheckboxField
                                label={"Passwort per Mail senden?"}
                                name={"sendMail"}
                                id={'sendMail'}
                                defaultChecked
                            />
                            <TextField label={"Passwort"} name="password" type="text"
                                       disabled={values.generatePassword}/>
                            <Form.Group className={"d-flex justify-content-end"}>
                                <ActionRowButton
                                    close={modal.hide}
                                    handleSubmit={handleSubmit}
                                    isSubmitting={isSubmitting}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
});

export default PasswordResetModal;
