import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

const Layout = ({children}) => {
    return (
        <Container fluid>
            <Row className={"justify-content-sm-center h-100 my-5"}>
                <Col xl={10} lg={12} sm={12}>
                    {children}
                </Col>
            </Row>
        </Container>
    );
};

export default Layout;