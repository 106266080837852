import React from 'react';
import {Card} from "react-bootstrap";

const WidgetWithTitleAndIcon = ({title, number, icon, className}) => {
    return (
        <Card className={"shadow-sm border-0 mb-4"}>
            <Card.Body>
                <div className="d-inline-block">
                    <div className="text-muted fw-bold">{title}</div>
                    <h2 className="mb-0">
                        {number}
                    </h2>
                </div>
                {/*<div className={`float-end icon-circle-medium  icon-box-lg ${className} mt-1`}>*/}
                {/*    {icon}*/}
                {/*</div>*/}
            </Card.Body>
        </Card>
    );
};

export default WidgetWithTitleAndIcon;