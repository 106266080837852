import {createSlice} from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: "user",
    initialState: {
        isAuthenticated: false,
        currentUser: {
            id: 0,
            username: "",
            email: "",
            active: false,
            enabled: false,
            roles: []
        },
    },
    reducers: {
        update: (state, action) => {
            state.currentUser = action.payload.user;
            state.isAuthenticated = action.payload.auth;
        },
        changeAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        updateDisplayName: (state, action) => {
            state.currentUser.displayName = action.payload;
        },
        updateEmail: (state, action) => {
            state.currentUser.email = action.payload;
        }
    }
});

export const {update, changeAuthenticated, updateDisplayName, updateEmail} = userSlice.actions;
export default userSlice.reducer;