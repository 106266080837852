import React, {useEffect} from "react";
import {Button, Spinner} from "react-bootstrap";
import PropTypes from "prop-types";
import {KeyCodes} from "../constants";
import "./Style.scss";

const ActionRowButton = ({text = "Speichern", handleSubmit, close, isSubmitting}) => {

    useEffect(() => {
        const handleKeyDown = event => {
            if (event.ctrlKey && event.keyCode === KeyCodes.ENTER) {
                event.preventDefault();
                handleSubmit();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleSubmit]);

    return (
        <div className="actions mt-3">
            <Button variant={"secondary"} onClick={close}>
                Schließen
            </Button>
            <Button type={"submit"} onClick={handleSubmit} disabled={isSubmitting}>
                {isSubmitting ? (
                    <>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-1"
                        />
                        {text}
                    </>
                ) : (
                    <span>{text}</span>
                )}
            </Button>
        </div>
    );
};

ActionRowButton.propTypes = {
    disable: PropTypes.bool,
};

ActionRowButton.defaultProps = {
    disable: false
};

export default ActionRowButton;
