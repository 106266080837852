import React, {useEffect, useRef, useState} from 'react';
import "./AppHeader.scss";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {BiMenu} from "react-icons/bi";
import useClickOutside from "../../utils/useClickOutside";
import {ACCESS_TOKEN, APP_NAME, GROUP_KEY} from "../../constants";
import {Link, NavLink, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {MdLogout, MdOutlineHome} from "react-icons/md";
import {useDispatch, useSelector} from "react-redux";
import {BsBox} from "react-icons/bs";
import {update} from "../../redux/UserSlice";
import {firstLetter, pageTitle} from "../../utils/Helpers";
import {HiOutlineUsers} from "react-icons/hi";
import {IoIosSwap} from "react-icons/io";
import {FiSettings} from "react-icons/fi";

const AppHeader = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const ref = useRef(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {

        // eslint-disable-next-line
    }, []);


    function click() {
        setOpen(!open);
    }

    const handleClose = () => setOpen(false);

    useClickOutside(ref, handleClose);

    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(GROUP_KEY);
        dispatch(update({user: null, auth: false}));
        navigate("/");
    }

    return (
        <div className={"d-flex vh-100"}>
            <button className={`sidebar-overlay ${!open ? 'd-none' : ''} opacity-50 bg-black d-xl-none`}
                    tabIndex="-1" type={"button"} aria-label={"close"} onClick={handleClose}></button>
            <div className={`sidebar ${open ? ' is-active' : ''}`}>
                <div className={"sidebar-header"}>
                    <Link to={"/"}>
                        <span className={"me-1"}><BsBox size={24} className={"me-1"}/></span>
                        {APP_NAME}
                    </Link>
                </div>
                <div className={"mb-3 text-white-50 text-center"}>
                    Admin
                </div>
                <div className={"mt-4 nav-list"}>
                    <NavLink to={"/"} className={"nav-link"} onClick={() => setOpen(false)}>
                        <MdOutlineHome className={"nav-icon"}/>
                        <span className={"nav-name"}>Dashboard</span>
                    </NavLink>
                    <NavLink to={"/users/list"} className={"nav-link"} onClick={() => setOpen(false)}>
                        <HiOutlineUsers className={"nav-icon"}/>
                        <span className={"nav-name"}>Benutzerverwaltung</span>
                    </NavLink>
                    <NavLink to={"/changelogs"} className={"nav-link"} onClick={() => setOpen(false)}>
                        <IoIosSwap className={"nav-icon"}/>
                        <span className={"nav-name"}>Changelogs</span>
                    </NavLink>
                    <NavLink to={"/settings"} className={"nav-link"} onClick={() => setOpen(false)}>
                        <FiSettings className={"nav-icon"}/>
                        <span className={"nav-name"}>Einstellungen</span>
                    </NavLink>
                </div>
                <div className={""}>
                    <div className={"nav-link"} onClick={handleLogout}>
                        <MdLogout className={"nav-icon"}/>
                        <span className={"nav-name"}>Ausloggen</span>
                    </div>
                </div>
            </div>
            <div className="page-wrapper">
                <Navbar variant="light" bg={"white"} className={`p-lg-2`}>
                    <Container fluid>
                        <Nav className="me-auto align-items-center">
                            <div className="me-2 d-xl-none">
                                <button className={"toggle-button"} onClick={click}><BiMenu size={24}/></button>
                            </div>
                            <Nav.Item className={"fw-bold"}>
                                {pageTitle(location.pathname)}
                            </Nav.Item>
                        </Nav>
                        <Nav>
                            <Nav.Item className="dropdown nav-user">
                                <NavDropdown
                                    align={"end"}
                                    title={
                                        <span className="avatar avatar-lg-32 bg-primary text-white rounded-circle">
                                            {firstLetter(user.currentUser.displayName)}
                                        </span>
                                    }
                                    id="nav-dropdown1"
                                    className="dropdown-menu-end nav-user-dropdown-custom"
                                >
                                    <div className="nav-user-info nav-user-dropdown py-2 px-2" role={"none"}>
                                        <p className={"small text-muted"}>Angemeldet als</p>
                                        <p>{user.currentUser.displayName}</p>
                                    </div>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item className={"ps-2"} onClick={handleLogout}>
                                        Ausloggen
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav.Item>
                        </Nav>
                    </Container>
                </Navbar>
                <div className={"app-content"}>
                    <Outlet/>
                </div>
            </div>
        </div>
    );
};

export default AppHeader;