import React from 'react';
import {useField} from 'formik';
import {Form} from "react-bootstrap";

export const CheckboxField = ({label, ...props}) => {
    const [field, meta] = useField(props);

    return (
        <Form.Group className="mb-3">
            <Form.Check
                label={label}
                id={field.name}
                isInvalid={!!meta.error}
                feedback={meta.error}
                feedbackType="invalid"
                {...field} {...props}
            />
        </Form.Group>
    )
}
