import React, {useEffect, useState} from 'react';
import Layout from "../layout/Layout";
import {Button, Card, Col, Form, ProgressBar, Row} from "react-bootstrap";
import WidgetClean from "../../shared/widgets/WidgetClean";
import {useNavigate} from "react-router-dom";
import {RiPencilLine} from "react-icons/ri";
import NiceModal from "@ebay/nice-modal-react";
import ChangelogDateAddModal from "./modals/ChangelogDateAddModal";
import ChangelogDateEditModal from "./modals/ChangelogDateEditModal";
import axios from "axios";
import CustomDate from "../../utils/CustomDate";

const ChangelogList = () => {
    let navigate = useNavigate();
    const [changelogs, setChangelogs] = useState([]);
    const [newCount, setNewCount] = useState(0);
    const [improveCount, setImproveCount] = useState(0);
    const [fixCount, setFixCount] = useState(0);
    const [changelogYears, setChangelogYears] = useState([]);

    useEffect(() => {
        loadChangelogYears();
        // eslint-disable-next-line
    }, []);

    function loadChangelogDates(year) {
        axios.get("/admin/changelogs?year=" + year).then(
            res => {
                setChangelogs(res.data.changelogs);
                setNewCount(res.data.newCount);
                setImproveCount(res.data.improvementCount);
                setFixCount(res.data.fixCount);
            }
        )
    }

    function handleClick(id) {
        navigate("/changelog-entry/" + id)
    }

    const handleAddModal = () => {
        NiceModal.show(ChangelogDateAddModal).then(() => {
            loadChangelogYears();
        });
    }

    const handleEditModal = (item) => {
        NiceModal.show(ChangelogDateEditModal, {item: item}).then(() => {
            loadChangelogYears();
        });
    }

    function loadChangelogYears() {
        axios.get("/admin/changelogs/years").then(
            res => {
                setChangelogYears(res.data);
                loadChangelogDates(res.data[0]);
            }
        )
    }

    return (
        <Layout>
            <h2 className={"mb-3"}>Changelogs</h2>
            <Row className={"mb-4"}>
                <Col xl={4} lg={4} md={6} sm={12}>
                    <WidgetClean title={"Neu"} number={newCount} color={"border-0 border-top border-primary"}/>
                </Col>
                <Col xl={4} lg={4} md={6} sm={12}>
                    <WidgetClean title={"Verbesserung"} number={improveCount}
                                 color={"border-0 border-top border-info"}/>
                </Col>
                <Col xl={4} lg={4} md={6} sm={12}>
                    <WidgetClean title={"Fix"} number={fixCount} color={"border-0 border-top border-danger"}/>
                </Col>
            </Row>
            <div className={"mb-4 d-flex justify-content-between"}>
                <Form.Select className={"w-25"} onChange={(e) => loadChangelogDates(e.target.value)}>
                    {changelogYears.map((name) => (
                        <option value={name} key={name}>{name}</option>
                    ))}
                </Form.Select>
                <Button onClick={handleAddModal}>
                    Hinzufügen
                </Button>
            </div>
            {Object.entries(changelogs).map(([key, value]) =>
                <React.Fragment key={key}>
                    <Row>
                        <Col>
                            <h2 className="font-24">{key}</h2>
                        </Col>
                    </Row>
                    <Row className="mb-4" key={key.id}>
                        {value.map(date =>
                            <Col sm={12} md={12} lg={3} xl={3} key={date.id}>
                                <div
                                    className={`card shadow border-top border-0 ${date.entryCount === 0 ? 'border-primary border-3' : ''}`}>
                                    {date.entryCount > 0 && (
                                        <ProgressBar className={"changelog-progressbar"}
                                                     style={{height: '4px'}}>
                                            <ProgressBar variant="primary" now={date.newPercentage} key={1}/>
                                            <ProgressBar variant="info" now={date.improvedPercentage} key={2}/>
                                            <ProgressBar variant="danger" now={date.fixPercentage} key={3}/>
                                        </ProgressBar>
                                    )}
                                    <Card.Body>
                                        <div className={"d-flex justify-content-between align-items-center"}>
                                            <div onClick={() => handleClick(date.id)}>
                                                <div
                                                    className="text-muted entry-header fw-bold">
                                                    <CustomDate dateString={date.date}/>
                                                </div>
                                                <h3 className="mb-0">
                                                    {date.entryCount}
                                                </h3>
                                            </div>
                                            <Button variant={"link"} className={"text-black"}>
                                                <RiPencilLine className="pencil" size={18}
                                                              onClick={() => handleEditModal(date)}/>
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </div>
                            </Col>
                        )}
                    </Row>
                </React.Fragment>
            )}
        </Layout>
    );
};

export default ChangelogList;