import React, {useEffect, useState} from "react";
import {Badge, Button, Card, Row} from "react-bootstrap";
import {errorNotification, successNotification} from "../../utils/Helpers";
import {useParams} from "react-router-dom";
import Layout from "../layout/Layout";
import LoadingIndicator from "../loading/LoadingIndicator";
import BackButton from "../../shared/BackButton";
import NiceModal from "@ebay/nice-modal-react";
import ChangelogEntryAddModal from "./modals/ChangelogEntryAddModal";
import ChangelogEntryEditModal from "./modals/ChangelogEntryEditModal";
import CustomDate from "../../utils/CustomDate";
import axios from "axios";


const ChangelogEntry = () => {
    const params = useParams();
    const [changeLogId, setChangeLogId] = useState(0);
    const [state, setState] = useState([])
    const [date, setDate] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (params.id) {
            setChangeLogId(params.id);
            loadEntry(params.id);
        }
    }, [params.id]);


    const handleEditModal = (item) => {
        NiceModal.show(ChangelogEntryEditModal, {entry: item}).then(() => {
            loadEntry(params.id);
        });
    }

    const handleAddModal = () => {
        NiceModal.show(ChangelogEntryAddModal, {id: params.id}).then(() => {
            loadEntry(params.id);
        });
    }

    function loadEntry(id) {
        setLoading(true);
        axios
            .get("/admin/changelog-entry/" + id)
            .then(
                res => {
                    setState(res.data);
                    if (res.data[0]) {
                        setDate(res.data[0].changelogDate)
                        setLoading(false);
                    } else {
                        axios
                            .get("/admin/changelogs/" + id).then(
                            result => {
                                setDate(result.data);
                                setLoading(false);
                            }
                        )
                    }
                }
            )
            .catch(() => {
            });

    }

    function handleDelete(id) {
        axios
            .delete("/admin/changelog-entry/" + id)
            .then(() => {
                successNotification("Changelog Eintrag erfolgreich gelöscht.");
                loadEntry(changeLogId);
            })
            .catch(() => {
                errorNotification("Sorry! Etwas ist schief gelaufen. Bitte versuche es erneut!");
            });
    }

    function badge(type) {
        switch (type) {
            case "NEW":
                return <Badge pill bg="success">
                    Neu
                </Badge>
            case "IMPROVED":
                return <Badge pill bg="info">
                    Verbesserung
                </Badge>
            case "FIX":
                return <Badge pill bg="danger">
                    Fix
                </Badge>
            default:
                return <Badge pill bg="success">
                    Neu
                </Badge>
        }
    }

    if (loading) {
        return <LoadingIndicator/>
    }

    return (
        <Layout>
            <BackButton/>
            <div className={"d-flex justify-content-between mb-4"}>
                <h3>
                    <CustomDate dateString={date}/>
                </h3>
                <Button onClick={handleAddModal}>
                    Hinzufügen
                </Button>
            </div>
            <Row>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    {state.map(item =>
                            <Card className={"shadow border-0 mb-4"} key={item.id}>
                                <Card.Body>
                                    <div className={"d-flex justify-content-between align-items-center"}>
                                        <div>
                                            {badge(item.type)}
                                            <h4 className={"mb-0"}>{item.title}</h4>
                                            <div>
                                                {item.text}
                                            </div>
                                        </div>
                                        <div className={"d-flex gap-3"}>
                                            <Button onClick={() => handleEditModal(item)}>
                                                Bearbeiten
                                            </Button>
                                            <Button variant={"danger"} onClick={() => handleDelete(item.id)}>
                                                Löschen
                                            </Button>
                                        </div>

                                    </div>
                                </Card.Body>
                            </Card>
                        // <div className="entry bg-white" key={item.id}>
                        //     <Row>
                        //         <Col md={1}>
                        //             <div className="label-wrapper">
                        //                 {badge(item.type)}
                        //             </div>
                        //         </Col>
                        //         <Col md={10}>
                        //             <div className="">
                        //                 <span className="entry-header">{item.title}</span>
                        //                 <div className={"entry-text"}>
                        //                     {item.text}
                        //                 </div>
                        //             </div>
                        //         </Col>
                        //         <Col>
                        //             <div
                        //                 className="custom-btn h-100 d-flex justify-content-end align-items-center"
                        //                 id="editbtn"
                        //             >
                        //                 <RiPencilLine className="pencil" size={18}
                        //                               onClick={() => handleEditModal(item)}/>
                        //             </div>
                        //         </Col>
                        //     </Row>
                        // </div>
                    )}
                </div>
            </Row>
        </Layout>
    )
}
export default ChangelogEntry