import React, {useEffect, useState} from 'react';
import Layout from "../layout/Layout";
import {Button, Card, Table} from "react-bootstrap";
import {MdLock} from "react-icons/md";
import {errorNotification} from "../../utils/Helpers";
import StatusBadge from "../../shared/StatusBadge";
import LoadingIndicator from "../loading/LoadingIndicator";
import NiceModal from "@ebay/nice-modal-react";
import UserAddModal from "./modals/UserAddModal";
import UserEditModal from "./modals/UserEditModal";
import {BiPencil} from "react-icons/bi";
import PasswordResetModal from "./modals/PasswordResetModal";
import axios from "axios";

const UserList = () => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        loadUsers();
        // eslint-disable-next-line
    }, []);

    function loadUsers() {
        setLoading(true);
        axios
            .get("/admin/users")
            .then((res) => {
                setUsers(res.data);
            })
            .catch(() => {
                errorNotification();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function createClick() {
        NiceModal.show(UserAddModal).then(() => {
            loadUsers();
        });
    }

    function editClick(user) {
        NiceModal.show(UserEditModal, {user: user}).then(() => {
            loadUsers();
        });
    }

    function passwordResetClick(user) {
        NiceModal.show(PasswordResetModal, {user: user});
    }

    if (loading) {
        return <LoadingIndicator/>
    }

    return (
        <Layout>
            <h2 className={"mb-3"}>Benutzerverwaltung</h2>
            <Card className={"shadow border-0 mb-4"}>
                <Card.Body>
                    <div className="d-flex justify-content-end mb-3">
                        {/*<div>*/}
                        {/*    <InputGroup>*/}
                        {/*        <FormControl*/}
                        {/*            placeholder="Suche Benutzer"*/}
                        {/*        />*/}
                        {/*        <Button variant="primary">*/}
                        {/*            <MdSearch/>*/}
                        {/*        </Button>*/}
                        {/*    </InputGroup>*/}
                        {/*</div>*/}
                        <Button onClick={createClick}>
                            Hinzufügen
                        </Button>
                    </div>
                    <div>
                        <Table responsive hover className={"align-middle"}>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Benutzer</th>
                                <th>Erstellt</th>
                                <th>Rolle</th>
                                <th>Status</th>
                                <th>E-Mail bestätigt</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {users.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>
                                        <div className={"d-flex flex-column"}>
                                            <strong>{item.username}</strong>
                                            <span>{item.email}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <customDate dateString={item.created} customFormat={'dd.MM.yyyy HH:mm'}/>
                                    </td>
                                    <td>
                                        {(item.roles.indexOf("Admin") > -1) && "Admin"}
                                        {!(item.roles.indexOf("Admin") > -1) && "Benutzer"}
                                    </td>
                                    <td>
                                        <StatusBadge status={item.active} yesText={"Aktiv"} noText={"Inaktiv"}/>
                                    </td>
                                    <td>
                                        <StatusBadge status={item.enabled} yesText={"Bestätigt"}
                                                     noText={"Nicht bestätigt"}/>
                                    </td>
                                    <td className={"text-end"}>
                                        <Button variant={"link"} onClick={() => editClick(item)}>
                                            <BiPencil/>
                                        </Button>
                                        <Button variant={"link"} onClick={() => passwordResetClick(item)}>
                                            <MdLock/>
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default UserList;
