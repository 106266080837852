import React from 'react';
import Header from "../header/Header";

const LayoutStatic = ({children}) => {
    return (
        <div className={"page-container"}>
            <div className={"content-wrap"}>
                <Header/>
                {children}
            </div>
        </div>
    );
};

export default LayoutStatic;