export const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api";
export const env = process.env.REACT_APP_ENVIRONMENT || "";
export const APP_NAME = process.env.REACT_APP_WEBSITE_NAME || "Invonizer";
export const ACCESS_TOKEN = "accessToken";
export const GROUP_KEY = "group";

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 24;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 100;

export const KeyCodes = {
    TAB: 9,
    ENTER: 13,
    ESCAPE: 27,
    SPACE: 32,
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40,
    M: 77,
};