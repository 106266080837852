import React from 'react';
import PropTypes from 'prop-types';
import {Spinner} from "react-bootstrap";

export default function LoadingIndicator({withMargin}) {
    return (
        <div className="d-flex justify-content-center">
            <Spinner
                animation="border"
                style={{
                    display: "block",
                    textAlign: "center",
                    marginTop: withMargin ? 15 : null,
                    color: "#5cb8b2"
                }}
            />
        </div>
    );
}
LoadingIndicator.propTypes = {
    withMargin: PropTypes.bool
};
LoadingIndicator.defaultProps = {
    withMargin: true
}
