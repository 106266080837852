import React from 'react';
import {Link} from "react-router-dom";
import "./NotFound.scss";

const NotFound = () => {
    return (
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1>404</h1>
                </div>
                <h2>Oops! Es wurde nichts gefunden</h2>
                <p>
                    Die Seite die du aufrufen wolltest, wurde eventuell gelöscht oder
                    ist im Moment nicht verfügbar. <br/>
                    <Link to="/">Zurück zur Startseite</Link>
                </p>
            </div>
        </div>
    );
};

export default NotFound;
