import React from 'react';

const WidgetClean = ({title, number, color}) => {
    return (
        <div className={`card border-3 shadow mb-3 mb-lg-1 ${color}`}>
            <div className="card-body">
                <h5 className="text-muted">{title}</h5>
                <div className="metric-value d-inline-block">
                    <h1 className="mb-1">{number}</h1>
                </div>
            </div>
        </div>
    );
};

export default WidgetClean;